import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Layout from "./Components/Layout";
import UserRoute from "./Components/Route/UserRoute";
import { HomePage, AuthCallbackPage } from "./Pages";
import DocumentView from "./Pages/DocumentViewer";
import Library from "./Pages/Library";
import ProcedureTemplates from "./Pages/ProcedureTemplates";
import TemplateGenerate from "./Pages/ProcedureTemplates/TemplateGenerate";
import ViewTemplateData from "./Pages/ProcedureTemplates/ViewTemplateData";
import EcoLocalization from "./Pages/ECO/EcoLocalization";
import ExceptionPage from "./Pages/ProcedureException/ExceptionPage";
import DocumentCompare from "./Pages/DocumentCompare";
import EcoView from "./Pages/ECO/EcoViewTable";
import DeviationPage from "./Pages/ECO/DeviationPage";
import Test from "./Pages/ECO/Test";
import EcoDocumentView from "./Pages/ECO/EcoDocumentViewer";
import ApproverDocumentView from "./Pages/ProcedureApproval/ApproverDocumentViewer";
import SetGeoGraphy from "./Pages/SetGeoGraphy";
import RichTextEditor from "./Components/LexicalEditor";
import TranslatedFileView from "./Pages/ECO/TranslatedFileViewer";
import ProcedureException from "./Pages/ProcedureException";
import LayoutForECO from "./Components/LayoutForECO";
import ExceptionViewPage from "./Pages/ProcedureException/ExceptionViewPage";
import ProcedureApproval from "./Pages/ProcedureApproval"
import FileUploadProcedure from "./Pages/ECO/UploadProcedures/FileUploadProcedure";
import ExceptionRenewalViewPage from "./Pages/ProcedureException/ExceptionRenewalViewPage";
import ExceptionRenewal from './Pages/ProcedureException/ExceptionRenewal';
import { UserRole } from "./Common/Enums";
import RoleProvider from "./Contexts/Providers/RoleProvider";
import ApproverTranslatedFileViewer from "./Pages/ProcedureApproval/ApproverTranslatedFileviewer";
import ExceptionApproval from "./Pages/ProcedureException/ExceptionApproval";
import RenewalApproval from "./Pages/ProcedureException/RenewalApproval";
import RTTool from "./Pages/RTTool";
import AdherenceTest from "./Pages/AdherenceTest";
import { RedirectRoute } from "./Components/Route/RedirectRoute";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import MSALLogin from "./Components/MSAL/MSALLogin";
import { useState, useEffect } from "react";

function App() {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeMSAL = async () => {
      try {
        await instance.initialize(); // Ensuring MSAL is initialized
        await instance.handleRedirectPromise();
        setIsInitialized(true);
      } catch (error) {
        console.error("MSAL Initialization Error:", error);
      }
    };

    initializeMSAL();
  }, [instance]);

  if (!isInitialized) {
    return <div>Initializing authentication...</div>;
  }

  console.log("isInitialized -> ", isInitialized)
  console.log("isAuthenticated -> ", isAuthenticated)

  return (
    <div>
      {isAuthenticated ? (
        <Router>
          <RoleProvider isAuthenticated={isAuthenticated}>
            <Switch>
              <RedirectRoute exact from="/" to="/procedure_templates" />
              {/* <Route path={["/home"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/home" component={HomePage} />
                  </Switch>
                
              </Route>
              <Route path={["/library"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/library" component={Library} />
                  </Switch>
                
              </Route> */}
              <Route path={["/richtext"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[]} path="/richtext" component={RichTextEditor} />
                  </Switch>
                
              </Route>
              <Route path={["/procedure_templates"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.READONLY_USER]} path="/procedure_templates" component={ProcedureTemplates} />
                  </Switch>
                
              </Route>
              <Route path={["/local_procedures"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/local_procedures" component={EcoView} />
                  </Switch>
                
              </Route>
              <Route path={["/exception_renewal"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/exception_renewal" component={ExceptionRenewal} />
                  </Switch>
                
              </Route>
              <Route path={["/exception_renew/:id"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/exception_renew/:id" component={ExceptionRenewalViewPage} />
                  </Switch>
                
              </Route>
              <Route path={["/template_generation"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER]} path="/template_generation" component={TemplateGenerate} />
                  </Switch>
                
              </Route>
              <Route path={["/lexical_editor"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[]} path="/lexical_editor" component={Test} />
                  </Switch>
                
              </Route>
              <Route path={["/raised_exception"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/raised_exception/:id" component={ExceptionViewPage} />
                  </Switch>
                
              </Route>
              <Route path={["/file_upload"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/file_upload" component={FileUploadProcedure} />
                  </Switch>
                
              </Route>
              <Route path={["/translated_file_view"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/translated_file_view" component={TranslatedFileView} />
                  </Switch>
                
              </Route>
              <Route path={["/document_view"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/document_view" component={DocumentView} />
                  </Switch>
                
              </Route>
              <Route path={["/document_compare/:id"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER]} path="/document_compare/:id" component={DocumentCompare} />
                  </Switch>
                
              </Route>
              <Route path={["/local_document_compare/:id"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER]} path="/local_document_compare/:id" component={DocumentCompare} />
                  </Switch>
                
              </Route>
              <Route path={["/view_template/:id"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.READONLY_USER]} path="/view_template/:id" component={ViewTemplateData} />
                  </Switch>
                
              </Route>
              <Route path={["/eco_localization/:id"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/eco_localization/:id" component={EcoLocalization} />
                  </Switch>
                
              </Route>
              <Route path={["/deviation"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER]} path="/deviation" component={DeviationPage} />
                  </Switch>
                
              </Route>
              <Route path={["/exception"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER]} path="/exception" component={ExceptionPage} />
                  </Switch>
                
              </Route>
              <Route path={["/eco_doc_viewer"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/eco_doc_viewer" component={EcoDocumentView} />
                  </Switch>
                
              </Route>
              <Route path={["/eco_doc_viewer_id/:id"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/eco_doc_viewer_id/:id" component={EcoDocumentView} />
                  </Switch>
                
              </Route>
              <Route path={["/setGeoGraphy"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/setGeoGraphy" component={SetGeoGraphy} />
                  </Switch>
                
              </Route>
              <Route path={["/procedure_exception"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.GLOBAL_USER, UserRole.ADMIN_USER, UserRole.ECO_USER, UserRole.READONLY_USER]} path="/procedure_exception" component={ProcedureException} />
                  </Switch>
                
              </Route>
              <Route path={["/approve_procedure"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.GLOBAL_USER]} path="/approve_procedure" component={ProcedureApproval} />
                  </Switch>
                
              </Route>
              <Route path={["/approver_doc_viewer/:id"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.GLOBAL_USER]} path="/approver_doc_viewer/:id" component={ApproverDocumentView} />
                  </Switch>
                
              </Route>
              <Route path={["/approver_translated_doc_viewer"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.GLOBAL_USER]} path="/approver_translated_doc_viewer" component={ApproverTranslatedFileViewer} />
                  </Switch>
                
              </Route>
              <Route path={["/exceptionapproval/:id"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.GLOBAL_USER, UserRole.READONLY_USER]} path="/exceptionapproval/:id" component={ExceptionApproval} />
                  </Switch>
                
              </Route>
              <Route path={["/renewalapproval/:id"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.GLOBAL_USER, UserRole.READONLY_USER]} path="/renewalapproval/:id" component={RenewalApproval} />
                  </Switch>
                
              </Route>
              <Route path={["/proceduretool"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER]} path="/proceduretool" component={RTTool} />
                  </Switch>
                
              </Route>
              <Route path={["/adherence"]}>
                
                  <Switch>
                    <UserRoute allowedRoles={[UserRole.ADMIN_USER, UserRole.ECO_USER]} path="/adherence" component={AdherenceTest} />
                  </Switch>
                
              </Route>
            </Switch>
          </RoleProvider>
          <Route path={["/auth/callback"]}>
              <Switch>
                <Route path="/auth/callback" component={AuthCallbackPage} />
              </Switch>
          </Route>
        </Router>
      ) : (
        <MSALLogin />
      )}
    </div>
    
  );
}

export default App;
