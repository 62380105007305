import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../msal_config";

const MSALLogin: React.FC = () => {
  const { instance } = useMsal();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeMSAL = async () => {
      if (!instance) return;

      try {
        console.log("Initializing MSAL instance...");
        await instance.initialize();
        console.log("MSAL instance initialized. Redirecting to login...");
        setIsInitialized(true);

        // Save the intended URL before login (fallback to home if not available)
        const redirectUri = window.location.origin + "/auth/callback";
        const state = JSON.stringify({ postLoginRedirect: window.location.pathname });

        await instance.loginRedirect({
          ...loginRequest,
          redirectUri,
          state,
        });

        await instance.handleRedirectPromise();
      } catch (e) {
        console.error("Login redirect failed:", e);
      }
    };

    initializeMSAL();
  }, [instance]);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return <div>Redirecting...</div>;
};

export default MSALLogin;
