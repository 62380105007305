import React, { Component } from 'react';
import { RoleContext } from '../index';
import AuthProviderService from '../../Services/AuthProviderService';
import { UserRole } from '../../Common/Enums';
import Utils from '../../Common/Utils';
import Layout from '../../Components/Layout';
import { Box, CircularProgress } from '@mui/material';
import { msalInstance } from '../../index';
import localStorageService from '../../Services/LocalStorageService';


interface Props extends React.PropsWithChildren { }
interface State {
    userRoles: UserRole[];
    userInfo: any;
    isLoading: boolean;
}

interface Props extends React.PropsWithChildren {
    isAuthenticated: boolean; // ✅ Add this
}


class RoleProvider extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            userRoles: [],
            userInfo: null,
            isLoading: true,
        }
    }

    componentDidMount(): void {
        this.fetchUserRoles();
    }

    fetchUserRoles = async () => {
        
        const isLoggedIn = this.props.isAuthenticated;

        console.log("isLoggedIn -> ", isLoggedIn)

        if (!isLoggedIn) {
            if (window.location.pathname !== "/auth/callback" && !window.location.pathname.includes("/logout") && !window.location.pathname.includes("/accessdenied")) {
                let pathName = window.location.pathname;
                if (window.location.search) {
                    pathName += window.location.search;
                }
                console.log("ERROR: User not logged in RoleProvider")
                // AuthProviderService.setCallbackPath(pathName);
                // AuthProviderService.login();
            }
        } else {

            console.log("getAllAccounts -> ", msalInstance.getAllAccounts()[0]);

            localStorageService.setUserLoggedIn();

            try {
                const userRoles = await AuthProviderService.getUserRoles();

                console.log('userRoles -> ', userRoles);

                const authModel = await AuthProviderService.getAccessToken();
                const userInfo: any = Utils.decodeJWT(authModel.accessToken);

                console.log("userInfo -> ", userInfo)

                this.setState({ userRoles, userInfo, isLoading: false });
            } catch (error) {
                console.error("Error in RoleProvider - ", error);
            }
        }
    }

    render() {
        if (this.state.isLoading) {
            return <Layout>
                <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '10em' }}><CircularProgress /></Box>
            </Layout>
        }
        

        return <RoleContext.Provider value={this.state}><Layout>{this.props.children} </Layout></RoleContext.Provider>;
    }
}

export default RoleProvider;