import { AuthClaimModel, AuthTokenModel } from '../Models';
import Utils from '../Common/Utils';
import { UserRole } from '../Common/Enums';
import { msalInstance } from '../index';
import { loginRequest } from '../msal_config';

class AuthProviderService {
    private readonly _CallbackPathName = 'callbackPath';

    setCallbackPath(path?: string | readonly string[]) {
        if (typeof path === 'string') {
            if (path === '/auth/login') {
                localStorage.setItem(this._CallbackPathName, '/');
            } else {
                localStorage.setItem(this._CallbackPathName, path);
            }
        } else {
            localStorage.setItem(this._CallbackPathName, '/');
        }
    }

    getCallbackPath(): string {
        const path = localStorage.getItem(this._CallbackPathName);
        if (path) {
            return path;
        }
        return '/';
    }

    handlerPromise(callback: () => void) {
        // AuthProvider.handlePromiseRedirectCallback(callback);
    }

    async isLoggedIn(): Promise<boolean> {
        const accounts = msalInstance.getAllAccounts();
        return accounts.length > 0;
    }

    async getAccessToken(): Promise<AuthTokenModel> {
        try {
            // Get the currently signed-in account
            const account = msalInstance.getAllAccounts()[0];
            if (!account) {
                throw new Error("No active account found. User may not be signed in.");
            }
    
            // Acquire token silently
            const response = await msalInstance.acquireTokenSilent({
                account: account,
                scopes: ["api://39b64b04-e385-4232-a4e2-604367e2d849/ApiGateway"],
            });
    
            return { accessToken: response.accessToken } as AuthTokenModel;
        } catch (error) {
            console.error("Failed to get access token:", error);
            throw error;
        }
    }

    getClaims(): AuthClaimModel | null {
        const accounts = msalInstance.getAllAccounts();
    
        if (accounts.length === 0) {
            console.error("No accounts found in MSAL instance.");
            return null;
        }
    
        const account = accounts[0]; // Assuming a single account scenario
    
        return {
            homeAccountId: account.homeAccountId,
            environment: account.environment,
            tenantId: account.tenantId,
            username: account.username,
            localAccountId: account.localAccountId,
            name: account.name,
            idTokenClaims: account.idTokenClaims,
        };
    }
    

    async getAdminEmails(): Promise<string[]> {
        const emails: string[] = [];
        const accessToken = await this.getUserRoleAccessToken();
        const env = Utils.getEnvVars();
    
        const headers = new Headers();
        const bearer = "Bearer " + accessToken;
        headers.append("Authorization", bearer);
        headers.append("ConsistencyLevel", "eventual");
        const options = {
            method: "GET",
            headers: headers
        };
    
        const graphAdminEndpoint = `https://graph.microsoft.com/v1.0/groups/${env.groups.EC_PMP_ADMIN_GROUP_ID}/members`;
        const responseAdmin = await fetch(graphAdminEndpoint, options);
        const adminData = await responseAdmin.json();
    
        if (adminData && adminData.value) {
            adminData.value.forEach((groupmember: any) => {
                emails.push(groupmember.mail);
            });
        }
    
        return emails;
    }

    async getUserRoleAccessToken() {
        const accounts = msalInstance.getAllAccounts(); // Get all logged-in accounts
        if (accounts.length === 0) {
          console.error("No active account found. User may need to log in.");
          return null;
        }
      
        try {
          const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0], // Use the first available account
          });
          return response.accessToken;
        } catch (error) {
          console.error("Silent token acquisition failed:", error);
          return null;
        }
    }
      

    async getUserRoles(): Promise<UserRole[]> {
        const userRoles: UserRole[] = [UserRole.GLOBAL_USER];

        // msalInstance.acquireTokenSilent

        const accessToken = await this.getUserRoleAccessToken();
        const loggedInUserToken = Utils.decodeJWT<any>(accessToken);
        const env = Utils.getEnvVars();

        const headers = new Headers();
        const bearer = "Bearer " + accessToken;
        headers.append("Authorization", bearer);
        headers.append("ConsistencyLevel", "eventual");
        const options = {
            method: "GET",
            headers: headers
        };

        const graphAdminEndpoint = `https://graph.microsoft.com/v1.0/groups/${env.groups.EC_PMP_ADMIN_GROUP_ID}/members`;
        const graphECOEndpoint = `https://graph.microsoft.com/v1.0/groups/${env.groups.EC_PMP_ECO_GROUP_ID}/members`;
        const graphReadonlyEndpoint = `https://graph.microsoft.com/v1.0/groups/${env.groups.EC_PMP_READONLY_GROUP_ID}/members`;

        const [responseAdmin, responseECO, responseReadonly] = await Promise.all([
            fetch(graphAdminEndpoint, options), fetch(graphECOEndpoint, options), fetch(graphReadonlyEndpoint, options)]);

        const adminData = await responseAdmin.json();
        const ecoData = await responseECO.json();
        const readonlyData = await responseReadonly.json();

        if (adminData && adminData.value) {
            adminData.value.forEach((groupmember: any) => {
                if (loggedInUserToken.unique_name === groupmember.mail) {
                    userRoles.push(UserRole.ADMIN_USER);
                }
            });
        }

        if (ecoData && ecoData.value) {
            ecoData.value.forEach((groupmember: any) => {
                if (loggedInUserToken.unique_name === groupmember.mail) {
                    userRoles.push(UserRole.ECO_USER);
                }
            });
        }

        if (readonlyData && readonlyData.value) {
            readonlyData.value.forEach((groupmember: any) => {
                if (loggedInUserToken.unique_name === groupmember.mail) {
                    userRoles.push(UserRole.READONLY_USER);
                }
            });
        }

        return userRoles;

  

       
    }
}

const authProviderService = new AuthProviderService();
export default authProviderService;