import { $getRoot, EditorState, createEditor } from "lexical";
import { $generateHtmlFromNodes } from "@lexical/html";
import Config from "../config.json";
import { FileType } from "./Enums";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { ListItemNode, ListNode } from "@lexical/list";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ImageNode } from "../Components/LexicalEditor/nodes/ImageNode";
import { isEqual } from "lodash";
import colors from "../Assets/colormapping.json";
import { v4 as uuid } from "uuid"
import jwtDecode from "jwt-decode";
import colorNamer from 'color-namer';

const editorStateEmptyJSONString =
  '{"root":{"children":[{"children":[],"direction":null,"format":"","indent":0,"type":"paragraph","version":1}],"direction":null,"format":"","indent":0,"type":"root","version":1}}';
// const editorStateContentJSONString =
//     '{ "root": { "children": [{ "children": [{ "detail": 0, "format": 0, "mode": "normal", "style": "", "text": "abdc", "type": "text", "version": 1 }], "direction": "ltr", "format": "", "indent": 0, "type": "paragraph", "version": 1 }], "direction": "ltr", "format": "", "indent": 0, "type": "root", "version": 1 } }';
const nodes = [
  TableCellNode,
  TableNode,
  TableRowNode,
  ImageNode,
  HorizontalRuleNode,
  AutoLinkNode,
  LinkNode,
  HeadingNode,
  QuoteNode,
  ListNode,
  ListItemNode,
];

const editor = createEditor({
  nodes,
});

class Utils {
  static getCurrentEnv(): any {
    if (process.env.APP_ENV) return process.env.APP_ENV;
    // Production
    if (
      window.location.hostname.includes("prod") ||
      window.location.hostname === "" ||
      window.location.hostname === ""
    ) {
      return "prod";
    }
    // Staging
    if (
      window.location.hostname.includes("qa") ||
      window.location.hostname === "" ||
      window.location.hostname === ""
    ) {
      return "qa";
    }
    // Develop
    return "dev";
  }

  static getEnvVars(): any {
    const env = Utils.getCurrentEnv();
    const envVariables: any = Object.assign({}, Config.Environment);
    return envVariables[env];
  }

  static getEditorState(jsonEditorState?: string) {
    const editorStateJSONString = jsonEditorState ?? editorStateEmptyJSONString;
    const editorState = editor.parseEditorState(editorStateJSONString);
    return editorState;
  }

  static isEditorStateEmpty(editorState: EditorState) {
    console.log("EditoremptyTest", editorState.isEmpty());
    // let editorEmptiness: any = editorState.toJSON().root.children[0];
    // console.log("someff", editorEmptiness.children.length);
    // console.log("checkEmptiness", checkEmptiness);
    const isEditorEmpty = editorState.read(() => {
      const root = $getRoot()

      // @ts-ignore
      const isEmpty = root.getFirstChild()!.isEmpty() && root.getChildrenSize() === 1
      
      return isEmpty;
    });
    console.log('isEmpty', isEditorEmpty)
    return isEditorEmpty;
  }


  // static isEditorStateEmpty(editorState: EditorState) {
  //   let data = editorState.toJSON()
  //   let empty = false;
  //   function traverse(obj: any) {

  //     if (obj && typeof obj === 'object') {
  //       if (obj.text?.trim() === "") {
  //         empty = true
  //       } 
  //       Object.keys(obj).forEach(value => {
  //         traverse(value);
  //       });
  //     }
  //     return empty
  //   }
  //   const emptiness = traverse(data);
  //   return emptiness;
  // }

  static decodeJWT<T>(token: string | null): T | null {
    if (token) {
      const decoded = jwtDecode<T>(token);
      return decoded;
    }

    return null;
  }
  static paginate<T>(array: T[], pageSize: number, pageNumber: number): T[] {
    return array?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  static getHTML(jsonEditorState?: string, improveHTML?: boolean): Promise<string> {
    const htmlEditor = createEditor({ nodes });

    const editorStateJSONString = jsonEditorState ?? editorStateEmptyJSONString;
    const editorState = editor.parseEditorState(editorStateJSONString);
    console.log(
      "jsoneditor",
      jsonEditorState,
      editorStateJSONString,
      editorState
    );
    htmlEditor.setEditorState(editorState);

    return new Promise((resolve) => {
      htmlEditor.update(() => {
        let htmlString = $generateHtmlFromNodes(htmlEditor, null);

        if (improveHTML) {
          htmlString = htmlString.replaceAll("<p>", "<div>").replaceAll("<p", "<div").replaceAll("</p>", "</div>");
        }
        
        return resolve(htmlString);
      });
    });
  }

  static isIE() {
    const isEdge = window.navigator.userAgent.indexOf("Edge") !== -1;
    const isIE =
      window.navigator.userAgent.indexOf("Trident") !== -1 && !isEdge;
    return isIE;
  }

  static getFileTypeNumber(fileType: FileType): number {
    switch (fileType) {
      case FileType.PDF:
        return 0;
      case FileType.DOCX:
        return 1;
      default:
        return -1;
    }
  }

  static isNumber(value: any): boolean {
    return !isNaN(parseInt(value));
  }


  static checkIfRedTextChanged(ogJson: { root: any }, newJson: { root: any }) {
    function traverse(node1: any, node2: any) {
      console.log("node1", node1);
      console.log("------------------------------------------------");
      console.log("node2", node2);
      if (
        (node1.rule && node1.rule.includes("LIMITED_RED_TEXT")) &&
        !isEqual(node1.text, node2 && node2.text)
      ) {
        return true;
      }
      if (
        node1.children &&
        node1.children.length > 0 &&
        node2 &&
        node2.children &&
        (node2 && node2.children.length) > 0
      ) {
        const currentChildren = node1.children;
        for (let index = 0; index < currentChildren.length; index++) {
          traverse(currentChildren[index], node2.children[index]);
        }
      }
    }
    traverse(ogJson.root, newJson.root);
    return false;
  }



  //  !(node1.style && node1.style.includes("background-color: yellow")) && !isEqual(node1.text, node2 && node2.text)
  static isRuleFromStyle(node: any, ruleType: string) {
    const styles = node.style?.split(';') ?? [];
    const rules = styles.filter((p: any) => p === `rule: ${ruleType}`);
    return rules.length > 0;
  }

  static textObjComparer() {
    let isValid = true;
    function traverse(node1: any, node2: any) {
      console.log("node1", node1)
      console.log("-------------------------------------")
      console.log("node2", node2)
      //      if (node1.rule === "BLACK_TEXT") {
      if (Utils.isRuleFromStyle(node1, 'NON_EDITABLE_TEXT')) {
        if (node1.text !== node2.text) {
          isValid = false
          console.log("isValid false")
          throw new Error(`Invalid text - ${JSON.stringify(node2)}`);
        }
      } else {
        if (
          //node1.rule === "LIMITED_BLACK_TEXT" || node1.rule === "YELLOW_TEXT"
          Utils.isRuleFromStyle(node1, 'LIMITED_BLACK_TEXT') || Utils.isRuleFromStyle(node1, 'EDITABLE_TEXT')
        ) {
          isValid = true
        }
        if (
          node1.children &&
          node1.children.length > 0 &&
          node2 &&
          node2.children &&
          (node2 && node2.children.length) > 0
        ) {
          const currentChildren = node1.children;
          for (let index = 0; index < currentChildren.length; index++) {
            let isValidNode = traverse(currentChildren[index], node2.children[index]);
            if (!isValidNode) {
              isValid = false
            }
          }
        }
        else {
          isValid = false
        }
      }
      console.log("isValid boolean", isValid)
      return isValid
    }
    return traverse
  }

  static uniqueIdToYellow(jsonData: any) {
    let data = jsonData
    console.log("uniqueIdToYellow")
    function traverse(obj: any) {

      if (obj && typeof obj === 'object') {
        if (obj.style && obj.style?.includes('background-color: yellow')) {
          const styles: string[] = obj.style?.split(';') ?? [];
          const uniqId = styles.some(p => p.includes('uuid'));

          if (!uniqId) {
            styles.push(`uuid: ${uuid()}`);
            obj.style = styles.join(';');
          }
        }
        Object.values(obj).forEach(value => {
          traverse(value);
        });
      }
    }
    traverse(data);
    let modifiedJsonStr = JSON.stringify(data);
    return modifiedJsonStr;
  }

  static nodeComparer(srcJson: any, newJson: any) {
    let data = srcJson
    function traverse(obj: any) {
      if (obj?.style?.includes('uuid')) {
        // const start = obj.style?.indexOf("uuid:") + "uuid:".length;
        // const end = obj.style?.indexOf(";", start);
        // const value = obj.style?.substring(start, end).trim();
        // console.log("textss", value)
        let styles: string[] = obj?.style?.split(";")
        let uuidValue: string | null = null;
        styles.forEach(property => {
          const [key, value] = property.split(':');
          if (key.trim() === 'uuid') {
            uuidValue = value.trim();
            console.log("uuid", uuidValue)
          }
        });
        let matchedObject = null;

        const searchObject = (object: any) => {
          if (typeof object !== 'object' || object === null) {
            return;
          }

          if (object.hasOwnProperty('style')) {
            const styleValue = object.style;
            const uuidPart = styleValue.match(/uuid: ([\w-]+)/);

            if (uuidPart && uuidPart[1] === uuidValue) {
              matchedObject = object;
              return;
            }
          }

          for (const key in object) {
            if (object.hasOwnProperty(key)) {
              searchObject(object[key]);
            }
          }
        };

        searchObject(newJson);

        if (matchedObject) {
          console.log("Match found:");
          console.log("matchedobj", matchedObject);
          let newObj: any = matchedObject
          // obj.style = newObj.style
          obj.text = newObj.text;
          console.log("newObj style", newObj?.style?.includes('background-color: yellow'))
          if (newObj?.style?.includes('background-color: yellow') === false) {

            let styles: string[] = obj?.style?.split(';') ?? [];
            styles = styles.filter((item) => item !== 'background-color: yellow');
            obj.style = styles.join(';')
          }
        } else {
          console.log(`No match found for UUID ${uuidValue}`);
        }
      }
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
          traverse(obj[key])
        }
      }
    }
    traverse(data);
    return JSON.stringify(data)
  }


  static extractLimitedBlackText(jsonData: any) {
    let limitedBlackTexts: any = [];
    const processChildren = (children: any) => {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.children && child.children.length > 0) {
          const firstChild = child.children[0];
          const lastChild = child.children[child.children.length - 1];
          if (
            firstChild.style === "background-color: cyan;" &&
            lastChild.style === "background-color: cyan;" &&
            lastChild.text?.startsWith("Delete")
          ) {

            limitedBlackTexts.push(firstChild);
            for (let j = 1; j < child.children.length - 1; j++) {
              const middleChild = child.children[j];
              if (middleChild.type === "text") {
                limitedBlackTexts.push(middleChild);
              }
            }

            limitedBlackTexts.push(lastChild);
          }
        }
        if (child.children && child.children.length > 0) {
          processChildren(child.children);
        }
      }
    };
    processChildren(jsonData.root.children);
    return limitedBlackTexts;
  }

  static addYellowAndBlackTextRule(jsonStr: any) {

    let data = jsonStr

    function traverse(obj: any) {

      if (obj && typeof obj === 'object') {
        if (obj.style && obj.style?.includes('background-color: yellow')) {
          //obj.rule = 'YELLOW_TEXT'
          const styles: string[] = obj.style?.split(';') ?? [];
          const editableTextRule = styles.find(p => p === 'rule: EDITABLE_TEXT');

          if (!editableTextRule) {
            styles.push('rule: EDITABLE_TEXT');
            obj.style = styles.join(';');
          }
        } else if (obj.style && obj.style?.includes('background-color: cyan')) {
          // do nothing
        }
        else {
          if (obj.text && obj.text !== "") {
            //obj.rule = 'BLACK_TEXT'
            const styles: string[] = obj.style?.split(';') ?? [];
            const nonEditableTextRule = styles.find(p => p === 'rule: NON_EDITABLE_TEXT');

            if (!nonEditableTextRule) {
              styles.push('rule: NON_EDITABLE_TEXT');
              styles.push('rule: RESTRICTED');
              obj.style = styles.join(';');
            }
          }
        }
        Object.values(obj).forEach(value => {
          traverse(value);
        });
      }
    }
    traverse(data);
    let modifiedJsonStr = JSON.stringify(data);
    return modifiedJsonStr;

  }

  static appendSpaceToLeftRightYellowText(jsonData: any) {
    const processChildren = (children: any) => {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
          /*  if any child contains style as  'background-color: yellow'
           then make its text , then append space to starting and ending */
           const Ith_Child = child;
           const Ith_ChildStyles: string[] = Ith_Child?.style?.split(';') ?? [];
           if(Ith_ChildStyles.includes('background-color: yellow'))
           { 
            let spaceAddedText = "";
            // Check for leading spaces
            const hasLeadingSpace = /^\s/.test(Ith_Child.text);
            // Check for trailing spaces
            // const hasTrailingSpace = /\s$/.test(Ith_Child.text);
            if (!hasLeadingSpace) {
              spaceAddedText =  ` ${Ith_Child.text}`;
            }else{
              spaceAddedText =  `${Ith_Child.text}`;
            }
            Ith_Child.text = spaceAddedText
           }
        if (child.children && child.children.length > 0) {
          processChildren(child.children);
        }
      }
    }
    processChildren(jsonData.root.children);
    return jsonData;
  }


  static appendRules(jsonData: any) {
    // const newJsonData = this.appendSpaceToLeftRightYellowText(jsonData)
    this.uniqueIdToYellow(jsonData);
    this.addYellowAndBlackTextRule(jsonData);
    const processChildren = (children: any) => {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.children && child.children.length > 0) {

          const firstChild = child.children[0];
          const firstChildStyles: string[] = firstChild.style?.split(';') ?? [];
          const lastChild = child.children[child.children.length - 1];
          const lastChildStyles: string[] = lastChild.style?.split(';') ?? [];
          if (
            // firstChild.style === "background-color: blue;" &&
            // lastChild.style === "background-color: blue;" &&
            // lastChild.text?.startsWith("[Delete")
            firstChildStyles.includes('background-color: cyan') &&
            lastChildStyles.includes('background-color: cyan') &&
            lastChild.text?.startsWith("[Delete")
          ) {
            //firstChild.rule = "LIMITED_BLACK_TEXT";
            //const firstChildStyles = firstChild.style?.split(';') ?? [];
            const limitedBTDeleteRuleFirst = firstChildStyles.find(p => p === 'rule: LIMITED_BLACK_TEXT_DELETE');

            if (!limitedBTDeleteRuleFirst) {
              firstChildStyles.push('rule: LIMITED_BLACK_TEXT_DELETE');
              firstChild.style = firstChildStyles.join(';');
            }

            for (let j = 1; j < child.children.length - 1; j++) {
              const middleChild = child.children[j];
              if (middleChild.type === "text") {
                //middleChild.rule = "LIMITED_BLACK_TEXT";
                let styles: string[] = middleChild.style?.split(';') ?? [];
                styles = styles.filter((item) => item !== 'rule: RESTRICTED');
                const limitedBTDeleteRuleMiddle = styles.find(p => p === 'rule: LIMITED_BLACK_TEXT_DELETE');

                if (!limitedBTDeleteRuleMiddle) {
                  styles.push('rule: LIMITED_BLACK_TEXT_DELETE');
                  middleChild.style = styles.join(';');
                }
              }
            }
            //lastChild.rule = "LIMITED_BLACK_TEXT";
            //const lastChildStyles = lastChild.style?.split(';') ?? [];
            const limitedBTDeleteRuleLast = lastChildStyles.find(p => p === 'rule: LIMITED_BLACK_TEXT_DELETE');

            if (!limitedBTDeleteRuleLast) {
              lastChildStyles.push('rule: LIMITED_BLACK_TEXT_DELETE');
              lastChild.style = lastChildStyles.join(';');
            }
          } else if (
            firstChildStyles.includes('background-color: cyan') &&
            lastChildStyles.includes('background-color: cyan') &&
            lastChild.text?.startsWith("[")
          ) {
            //firstChild.rule = "LIMITED_BLACK_TEXT";
            //const firstChildStyles = firstChild.style?.split(';') ?? [];
            const limitedBTDeleteRuleFirst = firstChildStyles.find(p => p === 'rule: LIMITED_BLACK_TEXT');

            if (!limitedBTDeleteRuleFirst) {
              firstChildStyles.push('rule: LIMITED_BLACK_TEXT');
              firstChild.style = firstChildStyles.join(';');
            }

            const editableChildStylesStringArray: any[] = [];
            child.children.forEach((item: { style: any; }) =>
              editableChildStylesStringArray.push(...item.style.split(";"))
            )

            const editableChildStylesfilter = editableChildStylesStringArray.filter((item: string) => item === 'rule: EDITABLE_TEXT')

            for (let j = 1; j < child.children.length - 1; j++) {
              const middleChild = child.children[j];
              console.log("middleChild", middleChild);
              if (middleChild.type === "text") {
                if (editableChildStylesfilter.length === 0) {
                  //middleChild.rule = "LIMITED_BLACK_TEXT";
                  let styles: string[] = middleChild.style?.split(';') ?? [];
                  styles = styles.filter((p: any) => !(p === 'rule: NON_EDITABLE_TEXT'));

                  const editedTextRuleMiddle = styles.find(p => p === 'rule: EDITABLE_TEXT');

                  if (!editedTextRuleMiddle) {
                    styles.push('rule: EDITABLE_TEXT');
                    middleChild.style = styles.join(';');
                  }
                }
              }
            }
            //lastChild.rule = "LIMITED_BLACK_TEXT";
            //const lastChildStyles = lastChild.style?.split(';') ?? [];
            const limitedBTRuleLast = lastChildStyles.find(p => p === 'rule: LIMITED_BLACK_TEXT');

            if (!limitedBTRuleLast) {
              lastChildStyles.push('rule: LIMITED_BLACK_TEXT');
              lastChild.style = lastChildStyles.join(';');
            }
          }

        }
        if (child.children && child.children.length > 0) {
          processChildren(child.children);
        }
      }
    };
    processChildren(jsonData.root.children);
    return JSON.stringify(jsonData);
  }

  static getHexCode(colorName: string) {
    const color = colors.find(p => p.color.toLowerCase() === colorName.toLowerCase());
    return color?.hex;
  }

  static getColorName(hexCode: string) {
    const colorNames = colorNamer(hexCode);
    return colorNames;
  }

  static isHexColor(hex: string) {
    return typeof hex === 'string'
        && hex.length === 6
        && !isNaN(Number('0x' + hex));
  }

  static isRGBColor(rgb: string) {
    return typeof rgb === 'string'
        && rgb.startsWith('rgb');
  }
}

export default Utils;