import { Box } from "@mui/material";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import RedButton from "../../Components/Button/RedButton";
import TabPanel from "../../Components/Tab/TabPanel";
import PageHeader from "../../Components/Text/PageHeader";
import TabBar from "./TabBar";
import { TabModel } from "./TabModel";
import AllDataTab from "./Tabs/AllDataTab";
import DraftedTab from "./Tabs/DraftedTab";
import SubmittedTab from "./Tabs/SubmittedTab";
import gptService from "../../Services/GPTService";
import axios from "axios";

interface Props extends RouteComponentProps<any, any, any> {}
interface State {
  currentTab: TabModel;
  count: {[index:string]:number};
  tabs: TabModel[];

}
class ProcedureTemplates extends React.Component<Props, State> {
  readonly Tabs: TabModel[] = [
    {
      name: "All",
      type: "All",
      index: 0,
      count:0,
    },
    {
      name: "Submitted",
      type: "Submitted",
      index: 1,
      count:0,
    },
    {
      name: "Drafted",
      type: "Drafted",
      index: 2,
      count:0,
    },
  ];
  constructor(props: Props) {
    super(props);
    this.state = {
      currentTab: this.Tabs[0],
      count:{},
      tabs: this.Tabs
    };
  }

  handleBadgeCount = (dataS: any, label?: string) => {
  if (label) {
    this.setState({count: {...this.state.count, [label]:dataS}}, () => {
      console.log("count",this.state.count)
    })
  }
  }

  getTabCount = async () => {
    try {
        let allCount = 0;
        let submittedCount = 0;
        let draftedCount = 0;

        const queryDrafted = {
            page: 0,
            limit: 0,
            searchtext: '',
            order: '',
            orderBy: '',
            status: "Drafted",
        }

        const queryAll = {
          page: 0,
          limit: 0,
          searchtext: '',
          order: '',
          orderBy: '',
          status: "",
      }

        const querySearchSumitted = {
            page: 0,
            limit: 0,
            searchtext: '',
            order: '',
            orderBy: '',
            status: "Submitted",
        }

        const dataAll = await gptService.getAllWithPagination(queryAll);
        if (dataAll?.count) {
            allCount = dataAll.count;
        }

        const dataSubmitted = await gptService.getAllWithPagination(querySearchSumitted);
        if (dataSubmitted?.count) {
          submittedCount = dataSubmitted.count;
        }

        const dataDrafted= await gptService.getAllWithPagination(queryDrafted);
        if (dataDrafted?.count) {
          draftedCount = dataDrafted.count;
        }
        const updatedTabs = [...this.state.tabs];
        updatedTabs[0].count = allCount;
        updatedTabs[1].count = submittedCount;
        updatedTabs[2].count = draftedCount;
        this.setState({ tabs: updatedTabs });

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log("error message:", error.message);
            return error.message;
        } else {
            console.log("unexpected error during fecthing:", error);
            return "An unexpected error occured";
        }
    }
};

  componentDidMount(): void {
    console.log("In procedure templates")
    let currentTab = sessionStorage.getItem("currentTab");
    console.log("In procedure templates currentTab -> ", currentTab)
    if(currentTab)
    {
        this.setState({ currentTab: JSON.parse(currentTab) });
    }
    this.getTabCount()
}

  render(): React.ReactNode {
    return (
      <>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <PageHeader label="Procedure Templates" />
          <Box style={{ display: "flex", justifyContent: "end" }}>
            <RedButton
              label="Generate Procedure Template"
              onClick={() => {
                this.props.history.push("/template_generation");
                // this.props.history.go(0);
              }}
            />
          </Box>
        </Box>
        <TabBar
          currentTab={this.state.currentTab}
          tabs={this.Tabs}
          countObj={this.state.count}
          onTabChange={this.handleTabChange}
        />
        <TabPanel value={this.state.currentTab.index}  index={0}>
          <AllDataTab handleBadgeCount={this.handleBadgeCount} />
        </TabPanel>
        <TabPanel value={this.state.currentTab.index} index={1}>
           <SubmittedTab />
        </TabPanel>
        <TabPanel value={this.state.currentTab.index} index={2}>
          <DraftedTab />
        </TabPanel>
      </>
    );
  }
  handleTabChange = (selectedTab: TabModel) => {
    this.setState({ currentTab: selectedTab });
    sessionStorage.setItem("currentTab", JSON.stringify(selectedTab));
}
}

export default ProcedureTemplates;
