import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, CircularProgress } from '@mui/material';

interface Props {
    selectedItems?: {};
    options: any[];
    onSelect: ((selectedValues: any) => void);
    setInputValue: ((InputValue: any) => void);
    placeholder?: string;
    loading?: boolean
}
interface State {
    selectedItems: {};
}
class SearchableAutoComplete extends React.Component<Props, State> {

    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            selectedItems: "",
        };
    }

    setInputValue = (event: any) => {
        this.props.setInputValue(event)
    }

    render() {
        const { options, selectedItems } = this.props;
        let chipStyle: React.CSSProperties = { borderColor: '#EE3A29' };
        let cancelIconStyle: React.CSSProperties = { color: '#EE3A29', height: "15px" };
        return (
            <Autocomplete
                size={"small"}
                // multiple
                options={options}
                autoComplete
                getOptionLabel={(option) =>  option ? option.text : ''}
                value={selectedItems}
                onChange={(event, newValue) => {
                    console.log('chnage called',newValue)
                    this.props.onSelect(newValue);
                  }}
                onInputChange={(event, newInputValue) => {
                    console.log('inpout called',newInputValue)
                    this.props.setInputValue(newInputValue);
                }}
                renderOption={(props, option, { index }) => (
                    // @ts-ignore
                    <li key={index}{...props} style={{height:'35px',fontSize:'15px'}}>
                        {option.text}
                    </li>
                )}
                renderInput={(params) => <TextField {...params} placeholder={this.props.placeholder}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {this.props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />}

            />
        );
    }


}

export default SearchableAutoComplete;